<template>
  <div class="list">
    <div v-for="item, index in items" :key="item.uuid" class="item">
      <DocumentFileItem
        v-bind="item"
        @input="(payload) => update(index, payload)"
      >
        <template #actions>
          <v-btn
            @click="() => remove(index)"
            :disabled="items.length < 2"
            title="Удалить"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </DocumentFileItem>
    </div>

    <div class="footer">
      <v-btn @click="create" color="success">
        Добавить файл
      </v-btn>
    </div>

  </div>
</template>

<script>
  import { v4 as uuid } from 'uuid'
  import isEqual from 'lodash/isEqual'
  import DocumentFileItem from './DocumentFileItem.vue'

  export default {
    components: {
      DocumentFileItem,
    },
    props: {
      value: {
        type: Array,
        default: () => ([])
      }
    },
    data () {
      return {
        items: [ ...this.value ].map((item) => ({ ...item, uuid: uuid() }))
      }
    },
    watch: {
      value (value) {
        const newValue = [ ...value ]
        if (!isEqual(newValue, this.items)) {
          this.items = newValue
        }
      },
      items (items) {
        this.$emit('input', items)
      }
    },
    methods: {
      getKey () {
        return uuid()
      },
      create () {
        this.items.push({ uuid: uuid() })
      },
      remove (index) {
        if (confirm('Удалить?')) {
          this.items.splice(index, 1)
        }

      },
      update (index, payload) {
        this.$set(this.items, index, payload)
      }
    }
  }
</script>

<style scoped>
  .list {
  }
  .item {
    border-top: 1px solid #eee;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .footer {
    border-top: 1px solid #eee;
    padding-top: 20px;
  }

</style>
